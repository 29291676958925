import React, { useState, useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import api from '../../services/api'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import AddCircle from '@material-ui/icons/AddCircle'
import Save from '@material-ui/icons/Save'
import Edit from '@material-ui/icons/Edit'
import Delete from '@material-ui/icons/Delete'
import LocalOffer from '@material-ui/icons/LocalOffer'
import BarMenu from '../BarMenu'
import AlertSnack from '../tools/AlertSnack'
import Loading from '../tools/Loading'
import ProductFooterBar from '../tools/ProductFooterBar'
import SubCategoryBoardStyle from './style'

const SubCategoryBoard = ( props ) => {

  const [openAlert, setOpenAlert] = useState(false)
  const [msgAlert, setMsgAlert] = useState('')
  const [typeAlert, setTypeAlert] = useState('success')
  const [loadRow, setLoadRow] = useState(true)
  const [redirect, setRedirect] = useState('')
  const [openLoading, setOpenLoading] = useState(false)
  const [edit, setEdit] = useState(false)
  const [id, setId] = useState('')
  const [category, setCategory] = useState(' ')
  const [categoryId, setCategoryId] = useState('')
  const [subCategory, setSubCategory] = useState('')

  const [listSubCategory, setListSubCategory] = useState()
  
  const classes = SubCategoryBoardStyle()

  useEffect(() => {
    const loadList = async () => {
      const id = props.match.params.id
      setCategoryId(id)
      const itens = await api.get(`/product/category/sub/list/${id}`)
      if(itens.status >= 200 && itens.status < 300){
        await setListSubCategory(itens.data)
      } else {
        setListSubCategory([])
      }
      const categoryTemp = await api.get(`/product/category/${id}`)
      setCategory(categoryTemp.data.category)
      setLoadRow(false)
      setOpenLoading(false)
    }
    loadList()
  }, [loadRow])

  const handleSubmit = async e => {
    e.preventDefault()
    setOpenLoading(true)
    if (edit) {
      try {
      const subCategoryTemp = {
        category_product_id: categoryId,
        sub_category: subCategory
      }
      await api.put(`/product/category/sub/${id}`, subCategoryTemp)
      setEdit(false)
      setOpenLoading(false)
      setLoadRow(true)
      setMsgAlert('Sub-Categoria editada com sucesso.')
      setTypeAlert('success')
      setOpenAlert(true)
      } catch (err) {
        setOpenLoading(false)
        setLoadRow(true)
        setMsgAlert('Erro ao editar sub-categoria.')
        setTypeAlert('error')
        setOpenAlert(true)
      }
    } else {
      try{
      const subCategoryTemp = {
        category_product_id: categoryId,
        sub_category: subCategory
      }
      await api.post('/product/category/sub', subCategoryTemp)
      setOpenLoading(false)
      setLoadRow(true)
      setMsgAlert('Sub-Categoria adicionada com sucesso.')
      setTypeAlert('success')
      setOpenAlert(true)
      } catch (err) {
        setOpenLoading(false)
        setLoadRow(true)
        setMsgAlert('Erro ao adicionar sub-categoria.')
        setTypeAlert('error')
        setOpenAlert(true)
      }
    }
    setSubCategory('')
    return listSubCategory
  }

  const handleEditBrand = subCategory => {
    setOpenLoading(true)
    setId(subCategory.id)
    setSubCategory(subCategory.sub_category)
    setEdit(true)
    setOpenLoading(false)
  }

  const handleRemoveBrand = async (e, subCategory) => {
    e.preventDefault()
    setOpenLoading(true)
    try {
      await api.delete(`/product/category/sub/${subCategory.id}`)
      setLoadRow(true)
      setMsgAlert('Sub-Categoria removida com sucesso.')
      setTypeAlert('info')
      setOpenAlert(true)
    } catch (err) {
      setMsgAlert('Erro ao remover sub-categoria.')
      setTypeAlert('error')
      setOpenAlert(true)
    }
    setOpenLoading(false)
  }

  const handleModAdd = () => {
    setOpenLoading(true)
    setSubCategory('')
    setEdit(false)
    setOpenLoading(false)
  }

  return (
    <>
    { redirect ? <Redirect to={redirect} /> : null}
    <BarMenu />
    <Grid container="fluid" className={classes.root} lg={12} xs={12}>
      {openAlert ? <AlertSnack 
          open={openAlert} 
          type={typeAlert} 
          msg={msgAlert} 
          setSnack={setOpenAlert} /> : null}
      <Grid className={classes.rowMain} item lg={12} xs={12}>

        <form onSubmit={e => handleSubmit(e)}>
          <Grid container="fluid" className={classes.boxFrm}>
           <Grid item lg={2} xs={4}>
            <TextField
              fullWidth
              disabled
              value={category}
              variant="outlined"
              className={classes.inpWhite}
              label="Categoria"/>
           </Grid>
           <Grid item lg={6} xs={6}>
            <TextField 
              required
              fullWidth 
              label="Sub-Categoria"
              variant="outlined"
              value={subCategory}
              onChange={e => setSubCategory(e.target.value)}
              className={classes.inpWhite}/>
           </Grid>
           <Grid item lg={4} xs={2}>
            <Button 
              className={classes.btnFrm} 
              type="submit"
              fullWidth 
              size="large"
              startIcon={edit ? <Save /> : <AddCircle />}>
             { edit ? 'Salvar' : 'Adicionar' }
            </Button>
           </Grid>
          </Grid>
        </form>

        <Grid container="fluid" className={classes.boxHeader} >
          <Grid item lg={10} xs={10}>
            <Typography noWrap={true} variant="h6" component="h6"> Sub-Categoria </Typography>
          </Grid>
          <Grid item className={classes.btnHeader} lg={2} xs={2}>
            { edit ?
              <IconButton onClick={e => handleModAdd()} color="primary">
                <AddCircle />
              </IconButton>
              : null
            }
          </Grid>
        </Grid>

        <Grid container="fluid" className={classes.listItem}>
        { listSubCategory ?
          listSubCategory.map((subCategory, index) => (
            <Grid key={index} container="fluid" lg={12} xs={12} className={classes.listRow}>
              <Grid item lg={10} xs={10}>
                <Typography noWrap={true} variant="body" component="div">{subCategory.sub_category.toUpperCase()}</Typography>
              </Grid>
              <Grid className={classes.boxAction} item lg={2} xs={2}>
                <IconButton onClick={e => handleEditBrand(subCategory)} color="primary">
                  <Edit />
                </IconButton>
                <IconButton onClick={e => handleRemoveBrand(e, subCategory)} color="secondary">
                  <Delete />
                </IconButton>
              </Grid>
            </Grid>
          )) : null
        }
        </Grid>

      </Grid>

      <Grid item className={classes.rowMenu} lg={12} xs={12}>
        <ProductFooterBar active={2} />
      </Grid>

    </Grid>

    <Loading open={openLoading} />
    </>
  )

}

export default SubCategoryBoard