import React, { useState, useEffect } from 'react'
import api from '../../services/api'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import AddCircle from '@material-ui/icons/AddCircle'
import Save from '@material-ui/icons/Save'
import Edit from '@material-ui/icons/Edit'
import Delete from '@material-ui/icons/Delete'
import BarMenu from '../BarMenu'
import AlertSnack from '../tools/AlertSnack'
import Loading from '../tools/Loading'
import ProductFooterBar from '../tools/ProductFooterBar'
import BrandBoardStyle from './style'

const BrandBoard = () => {

  const [openAlert, setOpenAlert] = useState(false)
  const [msgAlert, setMsgAlert] = useState('')
  const [typeAlert, setTypeAlert] = useState('success')
  const [loadRow, setLoadRow] = useState(true)
  const [openLoading, setOpenLoading] = useState(false)
  const [edit, setEdit] = useState(false)
  const [id, setId] = useState('')
  const [brand, setBrand] = useState('')

  const [listBrand, setListBrand] = useState()

  const classes = BrandBoardStyle()

  useEffect(() => {
    setOpenLoading(true)
    const loadBrand = async () => {
      const listTemp = await api.get('/brand')
      setListBrand(listTemp.data)
      setLoadRow(false)
      setOpenLoading(false)
    }
    loadBrand()
  }, [loadRow])

  const handleSubmit = async e => {
    e.preventDefault()
    setOpenLoading(true)
    if (edit) {
      const brandTemp = {
        brand: brand,
      }
      await api.put(`/brand/${id}`, brandTemp)
      setEdit(false)
      setOpenLoading(false)
      setLoadRow(true)
    } else {
      const brandTemp = {
        brand: brand,
      }
      try {
        const checkBrand = await api.get(`/brand/check/${brandTemp.brand}`)
        if (checkBrand.status === 200 && checkBrand.data) {
          const resBrand = await api.post('/brand', brandTemp)
          if (resBrand.status >= 200 && resBrand.status < 600) {
            setLoadRow(true)
            setOpenLoading(false)
            setMsgAlert('Marca adicionada com sucesso!')
            setTypeAlert('success')
            setOpenAlert(true)
          } else {
            setOpenLoading(false)
            setMsgAlert('Erro ao adicionar a marca.')
            setTypeAlert('error')
            setOpenAlert(true)
          }
        } else {
          setOpenLoading(false)
          setMsgAlert('Marca já cadastrada no sistema.')
          setTypeAlert('warning')
          setOpenAlert(true)
        }
      } catch (err) {
        setOpenLoading(false)
        setMsgAlert('Erro ao adicionar a marca.')
        setTypeAlert('error')
        setOpenAlert(true)
      }
    }
    setBrand('')
    return listBrand
  }

  const handleEditBrand = brand => {
    setOpenLoading(true)
    setId(brand.id)
    setBrand(brand.brand)
    setEdit(true)
    setOpenLoading(false)
  }

  const handleRemoveBrand = async brand => {
    setOpenLoading(true)
    try {
      const brandRes = await api.delete(`/brand/${brand.id}`)
      if (brandRes.status === 200) {
        //Codigo de sucesso
        setLoadRow(true)
        setOpenLoading(false)
        setMsgAlert('Marca removida com sucesso!')
        setTypeAlert('info')
        setOpenAlert(true)
      } else {
        //Codigo de alerta de erro
        setOpenLoading(false)
        setMsgAlert('Erro ao remover a marca.')
        setTypeAlert('error')
        setOpenAlert(true)
      }
    } catch (err) {
      setOpenLoading(false)
      setMsgAlert('Erro ao remover a marca.')
      setTypeAlert('error')
      setOpenAlert(true)
    }
  }

  const handleModAdd = () => {
    setOpenLoading(true)
    setBrand('')
    setEdit(false)
    setOpenLoading(false)
  }

  return (
    <>
      <BarMenu />
      <Grid container="fluid" className={classes.root} lg={12} xs={12}>
        {openAlert ? <AlertSnack 
          open={openAlert} 
          type={typeAlert} 
          msg={msgAlert} 
          setSnack={setOpenAlert} /> : null}

        <Grid className={classes.rowMain} item lg={12} xs={12}>

          <form onSubmit={e => handleSubmit(e)}>
            <Grid container="fluid" className={classes.boxFrm}>
              <Grid item lg={8} xs={8}>
                <TextField
                  required
                  fullWidth
                  label="Marca"
                  variant="outlined"
                  value={brand}
                  onChange={e => setBrand(e.target.value)}
                  className={classes.inpWhite} />
              </Grid>
              <Grid item lg={4} xs={4}>
                <Button
                  className={classes.btnFrm}
                  type="submit"
                  fullWidth
                  size="large"
                  startIcon={edit ? <Save /> : <AddCircle />}>
                  {edit ? 'Salvar' : 'Adicionar'}
                </Button>
              </Grid>
            </Grid>
          </form>

          <Grid container="fluid" className={classes.boxHeader} >
            <Grid item lg={10} xs={10}>
              <Typography noWrap={true} variant="h6" component="h6"> Marca </Typography>
            </Grid>
            <Grid item className={classes.btnHeader} lg={2} xs={2}>
              {edit ?
                <IconButton onClick={e => handleModAdd()} color="primary">
                  <AddCircle />
                </IconButton>
                : null
              }
            </Grid>
          </Grid>

          <Grid container="fluid" className={classes.listItem}>
            {listBrand ?
              listBrand.map((brand, index) => (
                <Grid key={index} container="fluid" lg={12} xs={12} className={classes.listRow}>
                  <Grid item lg={10} xs={10}>
                    <Typography noWrap={true} variant="body" component="div">{brand.brand.toUpperCase()}</Typography>
                  </Grid>
                  <Grid className={classes.boxAction} item lg={2} xs={2}>
                    <IconButton onClick={e => handleEditBrand(brand)} color="primary">
                      <Edit />
                    </IconButton>
                    <IconButton onClick={e => handleRemoveBrand(brand)} color="secondary">
                      <Delete />
                    </IconButton>
                  </Grid>
                </Grid>
              )) : null
            }
          </Grid>

        </Grid>

        <Grid item className={classes.rowMenu} lg={12} xs={12}>
          <ProductFooterBar active={3} />
        </Grid>

      </Grid>

      <Loading open={openLoading} />
    </>
  )

}

export default BrandBoard