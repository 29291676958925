import React, { useState, useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import api from '../../services/api'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import AddCircle from '@material-ui/icons/AddCircle'
import Save from '@material-ui/icons/Save'
import Edit from '@material-ui/icons/Edit'
import Delete from '@material-ui/icons/Delete'
import LocalOffer from '@material-ui/icons/LocalOffer'
import BarMenu from '../BarMenu'
import AlertSnack from '../tools/AlertSnack'
import Loading from '../tools/Loading'
import ProductFooterBar from '../tools/ProductFooterBar'
import CategoryBoardStyle from './style'

const CategoryBoard = () => {

  const [openAlert, setOpenAlert] = useState(false)
  const [msgAlert, setMsgAlert] = useState('')
  const [typeAlert, setTypeAlert] = useState('success')
  const [loadRow, setLoadRow] = useState(true)
  const [microMenu, setMicroMenu] = useState(false)
  const [openMicroMenu, setOpenMicroMenu] = useState(null)
  const [redirect, setRedirect] = useState('')
  const [openLoading, setOpenLoading] = useState(true)
  const [edit, setEdit] = useState(false)
  const [id, setId] = useState('')
  const [category, setCategory] = useState('')
  const [listCategory, setListCategory] = useState()
  
  const classes = CategoryBoardStyle()

  useEffect(() => {
    const loadList = async () => {
      const itens = await api.get('/product/category')
      await setListCategory(itens.data)
      setLoadRow(false)
      setOpenLoading(false)
    }
    loadList()
  }, [loadRow])

  useEffect(() => {
    const windowWidth = window.innerWidth
    if(windowWidth <= 1024){
      setMicroMenu(true)
    }
  }, [])

  const handleSubmit = async e => {
    e.preventDefault()
    setOpenLoading(true)
    if (edit) {
      try {
        const categoryTemp = {
          category: category
        }
        await api.put(`/product/category/${id}`, categoryTemp)
        setOpenLoading(false)
        setMsgAlert('Categoria editada com sucesso.')
        setTypeAlert('success')
        setOpenAlert(true)
      } catch (err) {
        setOpenLoading(false)
        setMsgAlert('Erro ao editar a categoria.')
        setTypeAlert('error')
        setOpenAlert(true)
      } 
    } else {
      try {
        const categoryTemp = {
          category: category,
        }
        await api.post('/product/category', categoryTemp)
        setOpenLoading(false)
        setMsgAlert('Categoria adicionada com sucesso.')
        setTypeAlert('success')
        setOpenAlert(true)
      } catch (err) {
        setOpenLoading(false)
        setMsgAlert('Erro ao adicionar categoria.')
        setTypeAlert('error')
        setOpenAlert(true)
      }
    }
    setLoadRow(true)
    setCategory('')
    return listCategory
  }

  const handleEditBrand = category => {
    setOpenLoading(true)
    document.querySelector('#inpCategory').focus()
    setCategory(category.category)
    setId(category.id)
    setEdit(true)
    setOpenLoading(false)
  }

  const handleRemoveBrand = async (e, category) => {
    setOpenLoading(true)
    e.preventDefault()
    try {
    await api.delete(`/product/category/${category.id}`)
    setLoadRow(true)
    setOpenLoading(false)
    setMsgAlert('Categoria removida com sucesso.')
    setTypeAlert('info')
    setOpenAlert(true)
    } catch (err) {
      setOpenLoading(false)
      setMsgAlert('Erro ao remover categoria.')
      setTypeAlert('error')
      setOpenAlert(true)
    }
  }

  const handleModAdd = () => {
    setOpenLoading(true)
    setCategory('')
    setEdit(false)
    setOpenLoading(false)
  }

  return (
    <>
    { redirect ? <Redirect to={redirect} /> : null}
    <BarMenu />
    <Grid container="fluid" className={classes.root} lg={12} xs={12}>
      {openAlert ? <AlertSnack 
          open={openAlert} 
          type={typeAlert} 
          msg={msgAlert} 
          setSnack={setOpenAlert} /> : null}
      <Grid className={classes.rowMain} item lg={12} xs={12}>

        <form onSubmit={e => handleSubmit(e)}>
          <Grid container="fluid" className={classes.boxFrm}>
           <Grid item lg={8} xs={8}>
            <TextField
              id="inpCategory"
              required
              fullWidth 
              label="Categoria"
              variant="outlined"
              value={category}
              onChange={e => setCategory(e.target.value)}
              className={classes.inpWhite}/>
           </Grid>
           <Grid item lg={4} xs={4}>
            <Button 
              className={classes.btnFrm} 
              type="submit"
              fullWidth 
              size="large"
              startIcon={edit ? <Save /> : <AddCircle />}>
             { edit ? 'Salvar' : 'Adicionar' }
            </Button>
           </Grid>
          </Grid>
        </form>

        <Grid container="fluid" className={classes.boxHeader} >
          <Grid item lg={10} xs={10}>
            <Typography noWrap={true} variant="h6" component="h6"> Categoria </Typography>
          </Grid>
          <Grid item className={classes.btnHeader} lg={2} xs={2}>
            { edit ?
              <IconButton onClick={e => handleModAdd()} color="primary">
                <AddCircle />
              </IconButton>
              : null
            }
          </Grid>
        </Grid>

        <Grid container="fluid" className={classes.listItem}>
        { listCategory ?
          listCategory.map((category, index) => (
            <Grid key={index} container="fluid" lg={12} xs={12} className={classes.listRow}>
              <Grid item lg={10} xs={10}>
                <Typography noWrap={true} variant="body" component="div">{category.category.toUpperCase()}</Typography>
              </Grid>
              { 
                !microMenu ?
                <Grid className={classes.boxAction} item lg={2} xs={2}>
                  <IconButton onClick={e => setRedirect(`/category/sub/${category.id}`)}>
                    <LocalOffer />
                  </IconButton>
                  <IconButton onClick={e => handleEditBrand(category)} color="primary">
                    <Edit />
                  </IconButton>
                  <IconButton onClick={e => handleRemoveBrand(e, category)} color="secondary">
                    <Delete />
                  </IconButton>
                </Grid>
                :
                <Grid className={classes.boxAction} item lg={2} xs={2}>
                  <IconButton
                    aria-label="more"
                    aria-controls="long-menu"
                    aria-haspopup="true" 
                    onClick={e => setOpenMicroMenu(e.currentTarget)}>
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    id="long-menu"
                    anchorEl={openMicroMenu}
                    keepMounted
                    open={Boolean(openMicroMenu)}
                    onClose={e => setOpenMicroMenu(null)}>
                    <MenuItem key={1} onClick={e => setRedirect(`/category/sub/${category.id}`)}>
                      <LocalOffer className={classes.iconMicroMenu} /> Sub-Categoria
                    </MenuItem>
                    <MenuItem key={2} onClick={e => handleEditBrand(category)}>
                      <Edit className={classes.iconMicroMenu} /> Editar
                    </MenuItem>
                    <MenuItem key={3} onClick={e => handleRemoveBrand(category)}>
                      <Delete className={classes.iconMicroMenu} /> Deletar
                    </MenuItem>
                  </Menu>
                </Grid>
              }
            </Grid>
          )) : 
          <Grid container="fluid" lg={12} xs={12} className={classes.listRow}>
            <Typography
              variant="body" 
              componet="div">
              <Loading open={openLoading} />
            </Typography>
          </Grid>
        }
        </Grid>

      </Grid>

      <Grid item className={classes.rowMenu} lg={12} xs={12}>
        <ProductFooterBar active={2} />
      </Grid>
      <Loading open={openLoading} />
    </Grid>

    </>
  )

}

export default CategoryBoard