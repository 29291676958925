import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import { isAuthenticated } from "./services/auth";

import Login from './pages/Login'
import Main from './pages/Main'
import ProductBoard from './pages/ProductBoard'
import FrmProduct from './pages/FrmProduct'
import UnityBoard from './pages/UnityBoard'
import BrandBoard from './pages/BrandBoard'
import CategoryBoard from './pages/CategoryBoard'
import SubCategoryBoard from './pages/SubCategoryBoard'

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isAuthenticated() ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: "/", state: { from: props.location } }} />
      )
    }
  />
);

const Routes = () => (
  <BrowserRouter>
    <Switch>
      <Route exact path="/" component={Login} />
      <Route path="/signup" component={() => <h1>SignUp</h1>} />
      <PrivateRoute path="/app" component={Main} />
      <PrivateRoute path="/product/add" component={FrmProduct}/>
      <PrivateRoute path="/product/:id" component={FrmProduct}/>
      <PrivateRoute path="/product" component={ProductBoard} />
      <PrivateRoute path="/unity" component={UnityBoard} />
      <PrivateRoute path="/brand" component={BrandBoard} />
      <PrivateRoute path="/category/sub/:id" component={SubCategoryBoard} />
      <PrivateRoute path="/category" component={CategoryBoard} />
      <Route path="*" component={Login} />
    </Switch>
  </BrowserRouter>
);

export default Routes;