import React from "react"
import Routes from "./routes"
import CssBaseline from '@material-ui/core/CssBaseline'

const App = () => {
  return (
    <>
      <CssBaseline />
      <Routes />
    </>
  )
}
export default App;