import React, { useState, useEffect } from 'react'
import api from '../../services/api'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import AddCircle from '@material-ui/icons/AddCircle'
import Save from '@material-ui/icons/Save'
import Edit from '@material-ui/icons/Edit'
import Delete from '@material-ui/icons/Delete'
import BarMenu from '../BarMenu'
import AlertSnack from '../tools/AlertSnack'
import Loading from '../tools/Loading'
import ProductFooterBar from '../tools/ProductFooterBar'
import UnityBoardStyle from './style'

const UnityBoard = () => {

  const [openAlert, setOpenAlert] = useState(false)
  const [msgAlert, setMsgAlert] = useState('')
  const [typeAlert, setTypeAlert] = useState('success')
  const [openLoading, setOpenLoading] = useState(true)
  const [edit, setEdit] = useState(false)
  const [id, setId] = useState('')
  const [unity, setUnity] = useState('')
  const [initials, setInitials] = useState('')
  const [factor, setFactor] = useState('')
  const [loadRow, setLoadRow] = useState(true)

  const [listUnities, setListUnities] = useState()
  
  const classes = UnityBoardStyle()

  useEffect(() => {
    const loadList = async () => {
      try{
        const itens = await api.get('/unity')
        await setListUnities(itens.data)
        setLoadRow(false)
        setOpenLoading(false)
      } catch (err) {
        setLoadRow(false)
        setOpenLoading(false)
        setMsgAlert("Erro ao carregar dados.")
        setTypeAlert('error')
        setOpenAlert(true)
      }
    }
    loadList()
  }, [loadRow])

  const handleSubmit = async e => {
    e.preventDefault()
    setOpenLoading(true)
    if (edit) {
      try {
        const unityTemp = {
          name: unity,
          initials: initials.toUpperCase(),
          factor: factor
        }
        await api.put(`/unity/${id}`, unityTemp)
        setOpenLoading(false)
        setMsgAlert('Unidade atualizada com sucesso.')
        setTypeAlert('success')
        setOpenLoading(true)
        setLoadRow(true)
      } catch (err) {
        setOpenLoading(false)
        setMsgAlert('Erro ao atualizar unidade.')
        setTypeAlert('error')
        setOpenLoading(true)
      }
      setEdit(false)
    } else {
      try {
        const unityTemp = {
          name: unity,
          initials: initials.toUpperCase(),
          factor: factor
        }
        await api.post('/unity', unityTemp)
        setOpenLoading(false)
        setMsgAlert('Unidade adicionada com sucesso.')
        setTypeAlert('success')
        setOpenAlert(true)
        setLoadRow(true)
      } catch (err) {
        setOpenLoading(false)
        setMsgAlert('Erro ao adicionar unidade.')
        setTypeAlert('error')
        setOpenLoading(true)
        setLoadRow(true)
      }
    }
    setUnity('')
    setInitials('')
    setFactor('')
    return listUnities
  }

  const handleEditUnity = unity => {
    setOpenLoading(true)
    setId(unity.id)
    setUnity(unity.name)
    setInitials(unity.initials)
    setFactor(unity.factor)
    setEdit(true)
    setOpenLoading(false)
  }

  const handleRemoveUnity = async (e, unity) => {
    e.preventDefault()
    setOpenLoading(true)
    try {
      await api.delete(`/unity/${unity.id}`)
      setOpenLoading(false)
      setMsgAlert('Unidade removida com sucesso.')
      setTypeAlert('success')
      setOpenAlert(true)
      setLoadRow(true)
    } catch (err) {
      setOpenLoading(false)
      setMsgAlert('Erro ao remover a unidade.')
      setTypeAlert('error')
      setOpenAlert(true)
    }
  }

  const handleModAdd = () => {
    setOpenLoading(true)
    setUnity('')
    setInitials('')
    setFactor('')
    setEdit(false)
    setOpenLoading(false)
  }

  return (
    <>
    <BarMenu />
    <Grid container="fluid" className={classes.root} lg={12} xs={12}>
      {openAlert ? <AlertSnack 
        open={openAlert} 
        type={typeAlert} 
        msg={msgAlert} 
        setSnack={setOpenAlert} /> : null}
      <Grid className={classes.rowMain} item lg={12} xs={12}>

        <form onSubmit={e => handleSubmit(e)}>
          <Grid container="fluid" className={classes.boxFrm}>
           <Grid item lg={3} xs={3}>
            <TextField 
              required
              fullWidth 
              label="Unidade"
              variant="outlined"
              value={unity}
              onChange={e => setUnity(e.target.value)}
              className={classes.inpWhite}/>
           </Grid>
           <Grid item lg={3} xs={3}>
           <TextField 
              required
              fullWidth 
              variant="outlined"
              value={initials}
              onChange={e => setInitials(e.target.value)}
              label="Sigla"/>
           </Grid>
           <Grid item lg={3} xs={3}>
            <TextField
              required
              type="text" 
              fullWidth 
              variant="outlined"
              value={factor}
              onChange={e => setFactor(e.target.value)}
              label="Fator"/>
           </Grid>
           <Grid item lg={3} xs={3}>
            <Button 
              className={classes.btnFrm} 
              type="submit"
              fullWidth 
              size="large"
              startIcon={edit ? <Save /> : <AddCircle />}>
             { edit ? 'Salvar' : 'Adicionar' }
            </Button>
           </Grid>
          </Grid>
        </form>

        <Grid container="fluid" className={classes.boxHeader} >
          <Grid item lg={6} xs={6}>
            <Typography noWrap={true} variant="h6" component="h6"> Unidade </Typography>
          </Grid>
          <Grid item lg={2} xs={2}>
            <Typography noWrap={true} variant="h6" component="h6"> Sigla </Typography>
          </Grid>
          <Grid item lg={2} xs={2}>
            <Typography noWrap={true} variant="h6" component="h6"> Fator </Typography>
          </Grid>
          <Grid item className={classes.btnHeader} lg={2} xs={2}>
            { edit ?
              <IconButton onClick={e => handleModAdd()} color="primary">
                <AddCircle />
              </IconButton>
              : null
            }
          </Grid>
        </Grid>

        <Grid container="fluid" className={classes.listItem}>
        { listUnities ?
          listUnities.map((unity, index) => (
            <Grid key={index} container="fluid" lg={12} xs={12} className={classes.listRow}>
              <Grid item lg={6} xs={6}>
                <Typography noWrap={true} variant="body" component="div">{unity.name.toUpperCase()}</Typography>
              </Grid>
              <Grid item lg={2} xs={2}>
              <Typography noWrap={true} variant="body" component="div">{unity.initials.toUpperCase()}</Typography>
              </Grid>
              <Grid item lg={2} xs={2}>
                <Typography noWrap={true} variant="body" component="div">{unity.factor}</Typography>
              </Grid>
              <Grid className={classes.boxAction} item lg={2} xs={2}>
                <IconButton onClick={e => handleEditUnity(unity)} color="primary">
                  <Edit />
                </IconButton>
                <IconButton 
                  color="secondary"
                  onClick={e => handleRemoveUnity(e, unity)}>
                  <Delete />
                </IconButton>
              </Grid>
            </Grid>
          )) : null
        }
        </Grid>

      </Grid>

      <Grid item className={classes.rowMenu} lg={12} xs={12}>
        <ProductFooterBar active={4} />
      </Grid>

    </Grid>

    <Loading open={openLoading} />
    </>
  )

}

export default UnityBoard